import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const Punching: FC = (): ReactElement => (
  // <svg id={KlosySVGId.punching} viewBox="0 0 142.593 17.548" x="0" y="0">
  //   <g
  //     id="Nóż_do_wykrawania"
  //     data-name="Nóż do wykrawania"
  //     transform="translate(0.274 0.273)"
  //   >
  //     <g
  //       id="Group_189"
  //       data-name="Group 189"
  //       transform="translate(-1693.437 -370.496)"
  //     >
  //       <path
  //         id="Path_343"
  //         data-name="Path 343"
  //         d="M1780.793,381.528l-1.738.3a5.076,5.076,0,0,0-4.5,4.232l-.075,1.436-15.566-.68-15.565-.982-15.566-1.209-10.805-1.058-10.655-1.36a62.1,62.1,0,0,1-12.882-3.183,57.506,57.506,0,0,1,18.428-4.464L1777.8,371.4a19.123,19.123,0,0,0,2.992,10.123Z"
  //         transform="translate(-1693.437 -370.496)"
  //         fill="var(--color-hover, transparent)"
  //         style={{ transition: '.3s' }}
  //         className="color-transition"
  //         stroke="#6b6965"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth="0.547"
  //       />
  //       <path
  //         id="Path_344"
  //         data-name="Path 344"
  //         d="M1828.049,383.266l2.326.2a.416.416,0,0,0,.47-.274l4.609-11.636a.42.42,0,0,0-.032-.356.414.414,0,0,0-.3-.2l-2.241-.125-13.752-.378h-13.828l-13.752.3-13.752.605a19.123,19.123,0,0,0,2.992,10.123v0h11.742l11.863.3,11.788.529Z"
  //         transform="translate(-1693.437 -370.496)"
  //         fill="var(--color-hover, transparent)"
  //         style={{ transition: '.3s' }}
  //         className="color-transition"
  //         stroke="#6b6965"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth="0.547"
  //       />
  //       <path
  //         id="Path_336"
  //         data-name="Path 336"
  //         d="M1805.532,372.649a.416.416,0,1,0-.416.416.416.416,0,0,0,.416-.416"
  //         stroke="#6b6965"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth="0.274"
  //       />
  //       <path
  //         id="Path_337"
  //         data-name="Path 337"
  //         d="M1805.532,379.148a.416.416,0,1,0-.416.415.416.416,0,0,0,.416-.415"
  //         stroke="#6b6965"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth="0.274"
  //       />
  //       <path
  //         id="Path_338"
  //         data-name="Path 338"
  //         d="M1826.462,372.8a.416.416,0,1,0-.416.416.415.415,0,0,0,.416-.416"
  //         fill="none"
  //         stroke="#6b6965"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth="0.274"
  //       />
  //       <path
  //         id="Path_339"
  //         data-name="Path 339"
  //         d="M1823.515,380.13a.416.416,0,1,0-.415.415.416.416,0,0,0,.415-.415"
  //         fill="none"
  //         stroke="#6b6965"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth="0.274"
  //       />
  //       <path
  //         id="Path_340"
  //         data-name="Path 340"
  //         d="M1783.694,373.254a.415.415,0,1,0-.415.415.415.415,0,0,0,.415-.415"
  //         fill="none"
  //         stroke="#6b6965"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth="0.274"
  //       />
  //       <path
  //         id="Path_341"
  //         data-name="Path 341"
  //         d="M1785.508,378.77a.416.416,0,1,0-.416.415.416.416,0,0,0,.416-.415"
  //         fill="none"
  //         stroke="#6b6965"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth="0.274"
  //       />
  //       <g
  //         id="Path_342"
  //         data-name="Path 342"
  //         fill="none"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       >
  //         <path
  //           d="M1828.049,383.266l4.836-12.392,2.241.125a.414.414,0,0,1,.3.2.42.42,0,0,1,.032.356l-4.609,11.636a.416.416,0,0,1-.47.274Z"
  //           stroke="none"
  //         />
  //         <path
  //           d="M 1830.45166015625 383.1976318359375 C 1830.512329101562 383.1976318359375 1830.56640625 383.1588134765625 1830.586303710938 383.1011047363281 C 1830.587646484375 383.0971374511719 1830.589111328125 383.0931701660156 1830.590698242188 383.0892639160156 L 1835.197998046875 371.4575500488281 C 1835.211791992188 371.4171142578125 1835.207397460938 371.3729553222656 1835.186645507812 371.33740234375 C 1835.166381835938 371.3031005859375 1835.133422851562 371.2793273925781 1835.09521484375 371.2712097167969 L 1833.067749023438 371.1581115722656 L 1828.436767578125 383.0245056152344 L 1830.398193359375 383.1914672851562 C 1830.407348632812 383.1922302246094 1830.416381835938 383.1934814453125 1830.425415039062 383.1951599121094 C 1830.43408203125 383.19677734375 1830.442993164062 383.1976318359375 1830.45166015625 383.1976318359375 M 1830.45166015625 383.4711303710938 C 1830.426391601562 383.4711303710938 1830.400756835938 383.4688110351562 1830.375 383.4639892578125 L 1828.048950195312 383.2659912109375 L 1832.885009765625 370.8739929199219 L 1835.1259765625 370.9989929199219 C 1835.2490234375 371.0169982910156 1835.358032226562 371.0899963378906 1835.421997070312 371.197998046875 C 1835.484985351562 371.3059997558594 1835.496948242188 371.4360046386719 1835.453979492188 371.5539855957031 L 1830.844970703125 383.1900024414062 C 1830.786376953125 383.3603210449219 1830.62646484375 383.4711303710938 1830.45166015625 383.4711303710938 Z"
  //           stroke="none"
  //           fill="#6b6965"
  //         />
  //       </g>
  //     </g>
  //   </g>
  // </svg>

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="142.593"
    height="17.548"
    viewBox="0 0 142.593 17.548"
    id={KlosySVGId.punching}
  >
    <g
      id="Nóż_do_wykrawania"
      data-name="Nóż do wykrawania"
      transform="translate(0.274 0.274)"
    >
      <path
        id="Path_343"
        data-name="Path 343"
        d="M1780.793,381.528l-1.738.3a5.076,5.076,0,0,0-4.5,4.232l-.075,1.436-15.566-.68-15.565-.982-15.566-1.209-10.805-1.058-10.655-1.36a62.1,62.1,0,0,1-12.882-3.183,57.506,57.506,0,0,1,18.428-4.464L1777.8,371.4a19.123,19.123,0,0,0,2.992,10.123Z"
        transform="translate(-1693.437 -370.496)"
        fill="none"
        stroke="#6b6965"
        strokeLinecap="round"
        className="color-transition"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <path
        id="Path_344"
        data-name="Path 344"
        d="M1828.049,383.266l2.326.2a.416.416,0,0,0,.47-.274l4.609-11.636a.42.42,0,0,0-.032-.356.414.414,0,0,0-.3-.2l-2.241-.125-13.752-.378h-13.828l-13.752.3-13.752.605a19.123,19.123,0,0,0,2.992,10.123v0h11.742l11.863.3,11.788.529Z"
        transform="translate(-1693.437 -370.496)"
        fill="none"
        stroke="#6b6965"
        strokeLinecap="round"
        className="color-transition"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <g
        id="Group_189"
        data-name="Group 189"
        transform="translate(-1693.437 -370.496)"
      >
        <path
          id="Path_336"
          data-name="Path 336"
          d="M1805.532,372.649a.416.416,0,1,0-.416.416.416.416,0,0,0,.416-.416"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_337"
          data-name="Path 337"
          d="M1805.532,379.148a.416.416,0,1,0-.416.415.416.416,0,0,0,.416-.415"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_338"
          data-name="Path 338"
          d="M1826.462,372.8a.416.416,0,1,0-.416.416.415.415,0,0,0,.416-.416"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_339"
          data-name="Path 339"
          d="M1823.515,380.13a.416.416,0,1,0-.415.415.416.416,0,0,0,.415-.415"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_340"
          data-name="Path 340"
          d="M1783.694,373.254a.415.415,0,1,0-.415.415.415.415,0,0,0,.415-.415"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_341"
          data-name="Path 341"
          d="M1785.508,378.77a.416.416,0,1,0-.416.415.416.416,0,0,0,.416-.415"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <g
          id="Path_342"
          data-name="Path 342"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M1828.049,383.266l4.836-12.392,2.241.125a.414.414,0,0,1,.3.2.42.42,0,0,1,.032.356l-4.609,11.636a.416.416,0,0,1-.47.274Z"
            stroke="none"
          />
          <path
            d="M 1830.45166015625 383.1976318359375 C 1830.512329101562 383.1976318359375 1830.56640625 383.1588134765625 1830.586303710938 383.1011047363281 C 1830.587646484375 383.0971374511719 1830.589111328125 383.0931701660156 1830.590698242188 383.0892639160156 L 1835.197998046875 371.4575500488281 C 1835.211791992188 371.4171142578125 1835.207397460938 371.3729553222656 1835.186645507812 371.33740234375 C 1835.166381835938 371.3031005859375 1835.133422851562 371.2793273925781 1835.09521484375 371.2712097167969 L 1833.067749023438 371.1581115722656 L 1828.436767578125 383.0245056152344 L 1830.398193359375 383.1914672851562 C 1830.407348632812 383.1922302246094 1830.416381835938 383.1934814453125 1830.425415039062 383.1951599121094 C 1830.43408203125 383.19677734375 1830.442993164062 383.1976318359375 1830.45166015625 383.1976318359375 M 1830.45166015625 383.4711303710938 C 1830.426391601562 383.4711303710938 1830.400756835938 383.4688110351562 1830.375 383.4639892578125 L 1828.048950195312 383.2659912109375 L 1832.885009765625 370.8739929199219 L 1835.1259765625 370.9989929199219 C 1835.2490234375 371.0169982910156 1835.358032226562 371.0899963378906 1835.421997070312 371.197998046875 C 1835.484985351562 371.3059997558594 1835.496948242188 371.4360046386719 1835.453979492188 371.5539855957031 L 1830.844970703125 383.1900024414062 C 1830.786376953125 383.3603210449219 1830.62646484375 383.4711303710938 1830.45166015625 383.4711303710938 Z"
            stroke="none"
            fill="#6b6965"
          />
        </g>
      </g>
    </g>
  </svg>
)
