import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const LightAsianCleaver: FC = (): ReactElement => (
  <svg
    id={KlosySVGId.lightAsianCleaver}
    viewBox="0 0 160.184 37.27"
    x="0"
    y="0"
  >
    <g
      id="Lekki_tasak_azjatycki"
      data-name="Lekki tasak azjatycki"
      transform="translate(0.273 0.273)"
    >
      <path
        id="Path_365"
        data-name="Path 365"
        d="M1836.79,473.141a.492.492,0,0,0-.354-.231l-2.418-.151-14.885-.151-14.962.227-14.961.529-14.885.906a19.667,19.667,0,0,0,3.837,11.173l12.635-.216,12.694.075,12.77.378,12.619.68,2.645.227a.49.49,0,0,0,.549-.332l4.76-12.7A.491.491,0,0,0,1836.79,473.141Z"
        transform="translate(-1677.228 -472.608)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <path
        id="Path_366"
        data-name="Path 366"
        d="M1778.162,485.443a8.175,8.175,0,0,0-6.255,7.189l-.076,16.245-13.828.378-13.827.076-13.9-.3-13.827-.528-7.859-.605-7.934-.831-7.858-1.134-7.783-1.36-7.783-1.662,2.947-5.969,3.1-5.894,3.4-5.743,3.551-5.591a4.048,4.048,0,0,1,2.267-1.889l27.277-.832,27.278-1.209,27.278-1.511A19.667,19.667,0,0,0,1778.162,485.443Z"
        transform="translate(-1677.228 -472.608)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <g
        id="Group_209"
        data-name="Group 209"
        transform="translate(-1677.228 -472.608)"
      >
        <path
          id="Path_367"
          data-name="Path 367"
          d="M1781.125,476.273a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_368"
          data-name="Path 368"
          d="M1783.241,482.393a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <g
          id="Path_369"
          data-name="Path 369"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M1834.018,472.759l2.418.151a.491.491,0,0,1,.4.65l-4.76,12.7a.49.49,0,0,1-.549.332l-2.645-.227Z"
            stroke="none"
          />
          <path
            d="M 1834.202392578125 473.0445861816406 L 1829.263671875 486.1183776855469 L 1831.548461914062 486.3144836425781 C 1831.556030273438 486.3151550292969 1831.563598632812 486.3161010742188 1831.571044921875 486.3173828125 C 1831.583374023438 486.3194885253906 1831.595703125 486.320556640625 1831.608032226562 486.320556640625 C 1831.7021484375 486.320556640625 1831.784790039062 486.2604064941406 1831.813720703125 486.1709289550781 C 1831.81494140625 486.1669006347656 1831.81640625 486.1629333496094 1831.81787109375 486.1589660644531 L 1836.576782226562 473.4668884277344 C 1836.597778320312 473.4056396484375 1836.590454101562 473.3389282226562 1836.557006835938 473.2841796875 C 1836.523559570312 473.2298278808594 1836.469360351562 473.1930236816406 1836.407104492188 473.1822509765625 L 1834.202392578125 473.0445861816406 M 1834.017944335938 472.7590026855469 L 1836.43603515625 472.9100036621094 C 1836.5830078125 472.9299926757812 1836.712036132812 473.0140075683594 1836.7900390625 473.1409912109375 C 1836.866943359375 473.2669982910156 1836.883056640625 473.4209899902344 1836.833984375 473.5599975585938 L 1832.073974609375 486.2550048828125 C 1832.007690429688 486.4600830078125 1831.817138671875 486.5940551757812 1831.608032226562 486.5940551757812 C 1831.58056640625 486.5940551757812 1831.552856445312 486.5917663574219 1831.525024414062 486.5870056152344 L 1828.880004882812 486.3599853515625 L 1834.017944335938 472.7590026855469 Z"
            stroke="none"
            fill="#6b6965"
          />
        </g>
        <path
          id="Path_370"
          data-name="Path 370"
          d="M1826.991,475.064a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_371"
          data-name="Path 371"
          d="M1805.229,475.215a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_372"
          data-name="Path 372"
          d="M1781.125,476.273a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_373"
          data-name="Path 373"
          d="M1783.241,482.393a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_374"
          data-name="Path 374"
          d="M1805.38,482.469a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_375"
          data-name="Path 375"
          d="M1826.991,475.064a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_376"
          data-name="Path 376"
          d="M1823.817,483.224a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
      </g>
    </g>
  </svg>
)
