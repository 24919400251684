import React, { FC, ReactElement } from 'react'
import { KlosySVGId } from '../types/svg'

export const Serrated: FC = (): ReactElement => (
  <svg id={KlosySVGId.serrated} viewBox="0 0 203.497 23.147" x="0" y="0">
    <g
      id="Nóż_do_pieczywa"
      data-name="Nóż do pieczywa"
      transform="translate(0.292 0.274)"
    >
      <path
        id="Path_386"
        data-name="Path 386"
        d="M1828.578,630.333l2.569.227a.492.492,0,0,0,.551-.33l4.756-12.393a.492.492,0,0,0-.4-.652l-2.418-.075-14.885-.227-14.886.151-14.885.529-14.808.819a17.715,17.715,0,0,0,3.366,10.67l12.726-.155,12.77.227,12.77.453Z"
        transform="translate(-1633.554 -616.883)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <path
        id="Path_387"
        data-name="Path 387"
        d="M1769.262,639.476l.076-2.8a8.027,8.027,0,0,1,6.864-7.584,11.757,11.757,0,0,1,1.34-.044,17.715,17.715,0,0,1-3.366-10.67l-26.507,1.732-26.446,1.436-26.522,1.058-26.447.68-26.522.378a11.578,11.578,0,0,0-5.007,1.714,7,7,0,0,0-3.091,4.7,2.229,2.229,0,0,0,2.978,2.66l.008.014a1.927,1.927,0,0,0,2.922.283l.025.02a1.927,1.927,0,0,0,2.921.283,1.95,1.95,0,0,0,1.355.761,1.925,1.925,0,0,0,1.523-.541,1.926,1.926,0,0,0,2.977.269,1.927,1.927,0,0,0,2.924.253,2.033,2.033,0,0,0,1.427.851,2,2,0,0,0,1.549-.567,5.541,5.541,0,0,1,4.267,1.01,1.929,1.929,0,0,0,1.516-.554,2.016,2.016,0,0,0,1.453.793,1.927,1.927,0,0,0,1.516-.566,1.949,1.949,0,0,0,1.43.868,1.925,1.925,0,0,0,1.55-.6,1.846,1.846,0,0,0,1.406.753,1.906,1.906,0,0,0,1.521-.569,5.375,5.375,0,0,1,4.377.994,1.8,1.8,0,0,0,1.443-.615,1.926,1.926,0,0,0,2.961.2,1.968,1.968,0,0,0,1.43.825,1.924,1.924,0,0,0,1.519-.6l0-.025a5.077,5.077,0,0,1,4.388,1.005,1.928,1.928,0,0,0,1.534-.628,1.863,1.863,0,0,0,1.413.779,1.943,1.943,0,0,1,2.957.151,1.923,1.923,0,0,0,1.506-.608,1.9,1.9,0,0,0,1.37.684,1.928,1.928,0,0,1,2.94.155,1.925,1.925,0,0,0,1.509-.617l.005.007a1.928,1.928,0,0,0,2.959.13l0,.032a1.928,1.928,0,0,0,1.441.747,2,2,0,0,0,1.517-.658,1.926,1.926,0,0,0,2.932.113,1.9,1.9,0,0,0,2.968.121,1.927,1.927,0,0,0,2.933.1,2.011,2.011,0,0,0,1.457.779,1.986,1.986,0,0,0,1.51-.643,1.925,1.925,0,0,0,1.447.719,1.963,1.963,0,0,0,1.5-.644,1.922,1.922,0,0,0,1.447.72,1.812,1.812,0,0,0,1.43-.637,1.928,1.928,0,0,0,2.934.069l.013.007a1.927,1.927,0,0,0,2.933.069,1.884,1.884,0,0,0,2.958,0,1.926,1.926,0,0,0,1.45.714,1.959,1.959,0,0,0,1.5-.631,5.384,5.384,0,0,1,4.4.783,1.928,1.928,0,0,0,1.481-.648,1.9,1.9,0,0,0,2.966.024,1.926,1.926,0,0,0,2.934.044,2,2,0,0,0,1.48.731,1.981,1.981,0,0,0,1.486-.692,1.928,1.928,0,0,0,2.935.029,2.017,2.017,0,0,0,1.482.739,1.963,1.963,0,0,0,1.483-.686,1.928,1.928,0,0,0,1.464.686,1.971,1.971,0,0,0,1.489-.678,1.927,1.927,0,0,0,2.935,0h.012a1.927,1.927,0,0,0,2.935,0l.026-.039a2.17,2.17,0,0,0,1.416.792l4.76.076"
        transform="translate(-1633.554 -616.883)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <g
        id="Group_217"
        data-name="Group 217"
        transform="translate(-1633.554 -616.883)"
      >
        <path
          id="Path_388"
          data-name="Path 388"
          d="M1804.851,619.414a.491.491,0,1,0-.491.492.491.491,0,0,0,.491-.492"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_389"
          data-name="Path 389"
          d="M1804.927,626.593a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_390"
          data-name="Path 390"
          d="M1826.538,619.339a.491.491,0,1,0-.492.491.491.491,0,0,0,.492-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_391"
          data-name="Path 391"
          d="M1823.364,627.5a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_392"
          data-name="Path 392"
          d="M1780.747,620.4a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_393"
          data-name="Path 393"
          d="M1782.788,626.517a.491.491,0,1,0-.492.491.491.491,0,0,0,.492-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <g
          id="Path_394"
          data-name="Path 394"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M1833.64,617.11l-5.062,13.223,2.569.227a.492.492,0,0,0,.551-.33l4.756-12.393a.492.492,0,0,0-.4-.652Z"
            stroke="none"
          />
          <path
            d="M 1833.825927734375 617.389404296875 L 1828.963012695312 630.0924072265625 L 1831.171020507812 630.2875366210938 C 1831.178833007812 630.2882080078125 1831.1865234375 630.2892456054688 1831.194091796875 630.2905883789062 C 1831.20654296875 630.292724609375 1831.21923828125 630.2938232421875 1831.231689453125 630.2938232421875 C 1831.325805664062 630.2938232421875 1831.408813476562 630.2338256835938 1831.438232421875 630.1444702148438 C 1831.439575195312 630.1402587890625 1831.441040039062 630.1361083984375 1831.442626953125 630.1319580078125 L 1836.197021484375 617.7431640625 C 1836.218017578125 617.6813354492188 1836.210815429688 617.6143188476562 1836.177612304688 617.5596923828125 C 1836.145141601562 617.5065307617188 1836.09228515625 617.4699096679688 1836.031372070312 617.4578247070312 L 1833.825927734375 617.389404296875 M 1833.640014648438 617.1099853515625 L 1836.057983398438 617.1849975585938 C 1836.204956054688 617.2059936523438 1836.333984375 617.291015625 1836.411010742188 617.4169921875 C 1836.488037109375 617.5440063476562 1836.503051757812 617.697998046875 1836.453979492188 617.8369750976562 L 1831.697998046875 630.22998046875 C 1831.630981445312 630.4336547851562 1831.440307617188 630.5673828125 1831.231689453125 630.5673828125 C 1831.203735351562 630.5673828125 1831.175415039062 630.56494140625 1831.14697265625 630.5599975585938 L 1828.578002929688 630.3330078125 L 1833.640014648438 617.1099853515625 Z"
            stroke="none"
            fill="#6b6965"
          />
        </g>
      </g>
    </g>
  </svg>
)
