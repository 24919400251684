import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const Santoku: FC = (): ReactElement => (
  <svg id={KlosySVGId.santoku} viewBox="0 0 164.763 29.864" x="0" y="0">
    <g
      id="Nóż_do_warzyw_i_ziół"
      data-name="Nóż do warzyw i ziół"
      transform="translate(0.273 0.274)"
    >
      <path
        id="Path_309"
        data-name="Path 309"
        d="M1828.653,215.476l2.6.22a.49.49,0,0,0,.549-.333l4.681-12.457a.491.491,0,0,0-.4-.649l-2.444-.079-14.885-.151-14.886.151-14.885.529-14.886.831a18.85,18.85,0,0,0,3.475,11.02l12.771-.215,12.77.076,12.769.377Z"
        transform="translate(-1672.298 -202.027)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <path
        id="Path_310"
        data-name="Path 310"
        d="M1698.99,228.775l24.1,1.134,24.1.906,24.029.529.385-9.307a8.126,8.126,0,0,1,5.961-7.479,18.85,18.85,0,0,1-3.475-11.02l-4.987.3-33.4,2.116-17,1.133-17,1.436-9.52,1.058a67.978,67.978,0,0,0-9.2,2.243,76.8,76.8,0,0,0-10.69,13.942l8.86,1.269,8.916.983,8.916.755"
        transform="translate(-1672.298 -202.027)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <g
        id="Group_171"
        data-name="Group 171"
        transform="translate(-1672.298 -202.027)"
      >
        <path
          id="Path_311"
          data-name="Path 311"
          d="M1826.538,204.482a.491.491,0,1,0-.492.491.491.491,0,0,0,.492-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_312"
          data-name="Path 312"
          d="M1823.44,212.643a.491.491,0,1,0-.492.491.491.491,0,0,0,.492-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_313"
          data-name="Path 313"
          d="M1780.747,205.842a.491.491,0,1,0-.491.492.491.491,0,0,0,.491-.492"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_314"
          data-name="Path 314"
          d="M1782.863,211.963a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <g
          id="Path_315"
          data-name="Path 315"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M1828.653,215.476l4.987-13.3,2.444.079a.491.491,0,0,1,.4.649l-4.681,12.457a.49.49,0,0,1-.549.333Z"
            stroke="none"
          />
          <path
            d="M 1831.3369140625 215.4295043945312 C 1831.430908203125 215.4295043945312 1831.513549804688 215.3689880371094 1831.542724609375 215.2789154052734 C 1831.544067382812 215.2748565673828 1831.54541015625 215.2707977294922 1831.546997070312 215.2667999267578 L 1836.226806640625 202.8130035400391 C 1836.247436523438 202.7522430419922 1836.23974609375 202.6855773925781 1836.206420898438 202.6309661865234 C 1836.173461914062 202.5777740478516 1836.119995117188 202.5413665771484 1836.058349609375 202.5298461914062 L 1833.827270507812 202.4577178955078 L 1829.035888671875 215.23388671875 L 1831.277099609375 215.4234466552734 C 1831.28466796875 215.4241027832031 1831.292358398438 215.4250640869141 1831.299926757812 215.4263458251953 C 1831.312255859375 215.4284515380859 1831.32470703125 215.4295043945312 1831.3369140625 215.4295043945312 M 1831.3369140625 215.7030181884766 C 1831.3095703125 215.7030181884766 1831.281860351562 215.7007293701172 1831.254028320312 215.6959991455078 L 1828.652954101562 215.4759979248047 L 1833.640014648438 202.1779937744141 L 1836.083984375 202.2570037841797 C 1836.23095703125 202.2769927978516 1836.360961914062 202.3609924316406 1836.43896484375 202.4869995117188 C 1836.515991210938 202.6130065917969 1836.532958984375 202.7669982910156 1836.484008789062 202.906005859375 L 1831.802978515625 215.3630065917969 C 1831.736694335938 215.5682220458984 1831.546630859375 215.7030181884766 1831.3369140625 215.7030181884766 Z"
            stroke="none"
            fill="#6b6965"
          />
        </g>
        <path
          id="Path_316"
          data-name="Path 316"
          d="M1804.625,204.482a.492.492,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_317"
          data-name="Path 317"
          d="M1804.927,211.661a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
      </g>
    </g>
  </svg>
)
