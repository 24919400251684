import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const Cleaver: FC = (): ReactElement => (
  <svg id={KlosySVGId.cleaver} viewBox="0 0 144.762 36.137" x="0" y="0">
    <g
      id="Ciężki_tasak"
      data-name="Ciężki tasak"
      transform="translate(0.274 0.273)"
    >
      <path
        id="Path_377"
        data-name="Path 377"
        d="M1829.031,558.838l2.645.3a.492.492,0,0,0,.554-.325l4.822-12.394a.491.491,0,0,0-.389-.655l-2.418-.152-14.886-.3h-14.885l-14.886.378-14.88.68A17.818,17.818,0,0,0,1778,557.1h12.724l12.77.3,12.77.529Z"
        transform="translate(-1692.87 -545.312)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <path
        id="Path_378"
        data-name="Path 378"
        d="M1774.708,546.37h-.081l-16.019.68-16.019.453-16.019.152-16.019-.076-15.943-.377a1.4,1.4,0,0,0-1.436,1.385l-.3,31.332,19.721.6,19.722.378h19.8l19.721-.3.09-16.083A8.159,8.159,0,0,1,1778,557.1,17.817,17.817,0,0,1,1774.708,546.37Zm-76.285,6.952a1.927,1.927,0,1,1,1.927-1.927A1.927,1.927,0,0,1,1698.423,553.322Z"
        transform="translate(-1692.87 -545.312)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <g
        id="Group_212"
        data-name="Group 212"
        transform="translate(-1692.87 -545.312)"
      >
        <path
          id="Path_379"
          data-name="Path 379"
          d="M1805.38,547.692a.491.491,0,1,0-.491.492.491.491,0,0,0,.491-.492"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_380"
          data-name="Path 380"
          d="M1805.456,554.946a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_381"
          data-name="Path 381"
          d="M1827.142,547.768a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_382"
          data-name="Path 382"
          d="M1823.893,555.929a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_383"
          data-name="Path 383"
          d="M1781.276,548.448a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_384"
          data-name="Path 384"
          d="M1783.316,554.644a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <g
          id="Path_385"
          data-name="Path 385"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M1829.031,558.838l5.214-13.224,2.418.152a.491.491,0,0,1,.389.655l-4.822,12.394a.492.492,0,0,1-.554.325Z"
            stroke="none"
          />
          <path
            d="M 1831.765014648438 558.8746337890625 C 1831.858032226562 558.8746337890625 1831.94091796875 558.8151245117188 1831.97119140625 558.7265014648438 C 1831.972412109375 558.722900390625 1831.973754882812 558.7193603515625 1831.97509765625 558.7158203125 L 1836.7958984375 546.3247680664062 C 1836.81787109375 546.2630615234375 1836.8115234375 546.196044921875 1836.77734375 546.1390380859375 C 1836.745971679688 546.0858154296875 1836.693603515625 546.0494384765625 1836.632690429688 546.0381469726562 L 1834.426391601562 545.8994750976562 L 1829.416259765625 558.606689453125 L 1831.70703125 558.8682861328125 C 1831.713256835938 558.8689575195312 1831.719482421875 558.869873046875 1831.7255859375 558.8710327148438 C 1831.738647460938 558.8734130859375 1831.751831054688 558.8746337890625 1831.765014648438 558.8746337890625 M 1831.765014648438 559.1481323242188 C 1831.735595703125 559.1481323242188 1831.705810546875 559.1455078125 1831.676025390625 559.1400146484375 L 1829.031005859375 558.8380126953125 L 1834.244995117188 545.614013671875 L 1836.662963867188 545.7659912109375 C 1836.808959960938 545.7869873046875 1836.93798828125 545.8729858398438 1837.012939453125 546 C 1837.088989257812 546.1270141601562 1837.10302734375 546.281982421875 1837.052001953125 546.4210205078125 L 1832.22998046875 558.8150024414062 C 1832.160888671875 559.0172119140625 1831.971435546875 559.1481323242188 1831.765014648438 559.1481323242188 Z"
            stroke="none"
            fill="#6b6965"
          />
        </g>
      </g>
    </g>
  </svg>
)
