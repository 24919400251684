import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const Boning: FC = (): ReactElement => (
  <svg id={KlosySVGId.boning} viewBox="0 0 151.561 14.828" x="0" y="0">
    <g
      id="Nóź_do_trybowania"
      data-name="Nóź do trybowania"
      transform="translate(0.274 0.273)"
    >
      <path
        id="Path_327"
        data-name="Path 327"
        d="M1781.05,331.265l-1.194.031a5.553,5.553,0,0,0-3.994,3.19l-1.991.028a19.2,19.2,0,0,0-10.8-4.76l-66.645.075a24.147,24.147,0,0,1-11.978-3.736l21.574-1.175,72.086-3.854A17.64,17.64,0,0,0,1781.05,331.265Z"
        transform="translate(-1684.443 -320.233)"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
      />
      <path
        id="Path_328"
        data-name="Path 328"
        d="M1828.049,332.927l2.342.3a.416.416,0,0,0,.47-.274l4.567-11.71a.416.416,0,0,0-.328-.555l-2.215-.08-13.752-.378h-13.828l-13.752.3-13.45.529a17.64,17.64,0,0,0,2.947,10.2h11.787l11.712.3,11.788.529Z"
        transform="translate(-1684.443 -320.233)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
        className="color-transition"
      />
      <g
        id="Group_183"
        data-name="Group 183"
        transform="translate(-1684.443 -320.233)"
      >
        <path
          id="Path_329"
          data-name="Path 329"
          d="M1805.532,322.311a.416.416,0,1,0-.416.416.416.416,0,0,0,.416-.416"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_330"
          data-name="Path 330"
          d="M1805.532,328.809a.416.416,0,1,0-.416.416.416.416,0,0,0,.416-.416"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_331"
          data-name="Path 331"
          d="M1826.462,322.538a.416.416,0,1,0-.416.415.416.416,0,0,0,.416-.415"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_332"
          data-name="Path 332"
          d="M1823.515,329.792a.416.416,0,1,0-.415.415.415.415,0,0,0,.415-.415"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_333"
          data-name="Path 333"
          d="M1783.694,322.916a.415.415,0,1,0-.415.415.415.415,0,0,0,.415-.415"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_334"
          data-name="Path 334"
          d="M1785.508,328.507a.416.416,0,1,0-.416.416.415.415,0,0,0,.416-.416"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <g
          id="Path_335"
          data-name="Path 335"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M1828.049,332.927l4.836-12.316,2.215.08a.416.416,0,0,1,.328.555l-4.567,11.71a.416.416,0,0,1-.47.274Z"
            stroke="none"
          />
          <path
            d="M 1830.467651367188 332.963623046875 C 1830.5283203125 332.963623046875 1830.582397460938 332.9248352050781 1830.602294921875 332.8670959472656 C 1830.603515625 332.8635864257812 1830.604736328125 332.8600769042969 1830.606079101562 332.8566284179688 L 1835.171875 321.14990234375 C 1835.185913085938 321.1101684570312 1835.181884765625 321.0659790039062 1835.16064453125 321.0303955078125 C 1835.140625 320.9964904785156 1835.108032226562 320.9726257324219 1835.070190429688 320.963623046875 L 1833.068725585938 320.8913269042969 L 1828.431640625 332.7007141113281 L 1830.426147460938 332.958740234375 C 1830.43115234375 332.9594116210938 1830.436279296875 332.960205078125 1830.441284179688 332.9611511230469 C 1830.450073242188 332.9627990722656 1830.458862304688 332.963623046875 1830.467651367188 332.963623046875 M 1830.467651367188 333.2371520996094 C 1830.442260742188 333.2371520996094 1830.416625976562 333.2348327636719 1830.390991210938 333.2300109863281 L 1828.048950195312 332.927001953125 L 1832.885009765625 320.6109924316406 L 1835.099975585938 320.6910095214844 C 1835.223999023438 320.7099914550781 1835.33203125 320.7829895019531 1835.39599609375 320.8909912109375 C 1835.4599609375 320.9979858398438 1835.470947265625 321.1289978027344 1835.427978515625 321.2460021972656 L 1830.860961914062 332.9559936523438 C 1830.802490234375 333.1263122558594 1830.642456054688 333.2371520996094 1830.467651367188 333.2371520996094 Z"
            stroke="none"
            fill="#6b6965"
          />
        </g>
      </g>
    </g>
  </svg>
)
