import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const Chef240: FC = (): ReactElement => (
  <svg id={KlosySVGId.chef240} viewBox="0 0 189.645 34.004" x="0" y="0">
    <g
      id="Nóż_szefa_kuchni_240_mm"
      data-name="Nóż szefa kuchni 240 mm"
      transform="translate(0.274 0.274)"
    >
      <g
        id="Group_280"
        data-name="Group 280"
        transform="translate(-1656.127 -31.722)"
      >
        <path
          id="Path_440"
          data-name="Path 440"
          d="M1777.833,45.189l12.737-.471,12.77-.151,12.77.076,12.77.453,2.611.146a.491.491,0,0,0,.542-.345l4.379-12.532a.491.491,0,0,0-.413-.64l-.016,0h-2.418l-14.886.151-14.885.6-14.81.907-14.809,1.231A17.72,17.72,0,0,0,1777.833,45.189Z"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.547"
          fill="var(--color-hover, transparent)"
          style={{ transition: '.3s' }}
          className="color-transition"
        />
        <g id="Group_279" data-name="Group 279">
          <path
            id="Path_441"
            data-name="Path 441"
            d="M1804.851,34.858a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
            fill="none"
            stroke="#6b6965"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.274"
          />
          <path
            id="Path_442"
            data-name="Path 442"
            d="M1805.154,42.036a.492.492,0,1,0-.491.491.491.491,0,0,0,.491-.491"
            fill="none"
            stroke="#6b6965"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.274"
          />
          <path
            id="Path_443"
            data-name="Path 443"
            d="M1826.538,34.1a.491.491,0,1,0-.492.491.491.491,0,0,0,.492-.491"
            fill="none"
            stroke="#6b6965"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.274"
          />
          <path
            id="Path_444"
            data-name="Path 444"
            d="M1823.591,42.338a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
            fill="none"
            stroke="#6b6965"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.274"
          />
          <path
            id="Path_445"
            data-name="Path 445"
            d="M1780.823,36.444a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
            fill="none"
            stroke="#6b6965"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.274"
          />
          <path
            id="Path_446"
            data-name="Path 446"
            d="M1783.014,42.565a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
            fill="none"
            stroke="#6b6965"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.274"
          />
          <g
            id="Path_447"
            data-name="Path 447"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path
              d="M1828.88,45.1l2.611.146a.491.491,0,0,0,.542-.345l4.379-12.532a.491.491,0,0,0-.413-.64l-.016,0h-2.418Z"
              stroke="none"
            />
            <path
              d="M 1831.562255859375 44.97364044189453 C 1831.657836914062 44.97364044189453 1831.74365234375 44.90991973876953 1831.770874023438 44.81867980957031 C 1831.772094726562 44.81468200683594 1831.773315429688 44.81071090698242 1831.774780273438 44.80677032470703 L 1836.15283203125 32.27755355834961 C 1836.171997070312 32.21702194213867 1836.162841796875 32.14901733398438 1836.127685546875 32.09455108642578 C 1836.092529296875 32.03961944580078 1836.034790039062 32.00403213500977 1835.969482421875 31.99691009521484 C 1835.9658203125 31.99651908874512 1835.962280273438 31.99605369567871 1835.958618164062 31.99551963806152 L 1833.758911132812 31.99551963806152 L 1829.258422851562 44.84321594238281 L 1831.506225585938 44.96891021728516 C 1831.514282226562 44.9693603515625 1831.522338867188 44.97016143798828 1831.5302734375 44.97132110595703 C 1831.540893554688 44.97285842895508 1831.551635742188 44.97364044189453 1831.562255859375 44.97364044189453 M 1831.562255859375 45.24716186523438 C 1831.538696289062 45.24716186523438 1831.514892578125 45.2454719543457 1831.490966796875 45.24200057983398 L 1828.880004882812 45.09600067138672 L 1833.56494140625 31.72200012207031 L 1835.983032226562 31.72200012207031 L 1835.9990234375 31.72500038146973 C 1836.14599609375 31.74100112915039 1836.277954101562 31.82200050354004 1836.358032226562 31.94700050354004 C 1836.43798828125 32.07099914550781 1836.4580078125 32.22499847412109 1836.411987304688 32.3650016784668 L 1832.032958984375 44.89699935913086 C 1831.969970703125 45.10779190063477 1831.7763671875 45.24717330932617 1831.562255859375 45.24716186523438 Z"
              stroke="none"
              fill="#6b6965"
            />
          </g>
        </g>
        <path
          id="Path_448"
          data-name="Path 448"
          d="M1656.127,52.1l4.981,1.509,4.982,1.284,5.057.981,10.265,1.585,10.34,1.207,10.416.755,22.87.981,22.869.6h24.078l.077-8.225a8.114,8.114,0,0,1,5.8-7.591l-.033-.017a17.7,17.7,0,0,1-3.654-10.562v-.022L1753.8,36.4l-59.779,5.057-6.113.754-6.038.906L1675.9,44.33a60.663,60.663,0,0,0-8.456,2.564l.035-.024a62.431,62.431,0,0,0-11.326,5.247Z"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.547"
          fill="var(--color-hover, transparent)"
          style={{ transition: '.3s' }}
          className="color-transition"
        />
      </g>
    </g>
  </svg>
)
