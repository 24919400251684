import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const Filleting: FC = (): ReactElement => (
  <svg id={KlosySVGId.filleting} viewBox="0 0 178.004 17.441" x="0" y="0">
    <g
      id="Nóż_do_filetowania"
      data-name="Nóż do filetowania"
      transform="translate(0.274 0.273)"
    >
      <path
        id="Path_318"
        data-name="Path 318"
        d="M1777.565,279.064a8.122,8.122,0,0,0-5.634,5.126l-2.757.03a16.588,16.588,0,0,0-11.081-5.225l-83.717-.5a30.481,30.481,0,0,1-15.167-4.49l27.126-1.284,87.92-4.38A17.811,17.811,0,0,0,1777.565,279.064Z"
        transform="translate(-1659.209 -267.326)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <path
        id="Path_319"
        data-name="Path 319"
        d="M1828.578,280.927l2.592.294a.491.491,0,0,0,.555-.324l4.907-12.381a.485.485,0,0,0-.038-.42.49.49,0,0,0-.35-.236l-2.453-.081-14.885-.378-14.886-.075-14.846.337-14.919.682a17.811,17.811,0,0,0,3.31,10.719l12.7.049,12.77.3,12.77.6Z"
        transform="translate(-1659.209 -267.326)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <g
        id="Group_177"
        data-name="Group 177"
        transform="translate(-1659.209 -267.326)"
      >
        <path
          id="Path_320"
          data-name="Path 320"
          d="M1805,269.706a.492.492,0,1,0-.492.491.492.492,0,0,0,.492-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_321"
          data-name="Path 321"
          d="M1805,276.96a.492.492,0,1,0-.492.491.492.492,0,0,0,.492-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_322"
          data-name="Path 322"
          d="M1826.689,269.857a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_323"
          data-name="Path 323"
          d="M1823.44,278.018a.491.491,0,1,0-.492.491.491.491,0,0,0,.492-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_324"
          data-name="Path 324"
          d="M1780.823,270.386a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_325"
          data-name="Path 325"
          d="M1782.863,276.582a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <g
          id="Path_326"
          data-name="Path 326"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M1828.578,280.927l5.213-13.148,2.453.081a.49.49,0,0,1,.35.236.485.485,0,0,1,.038.42l-4.907,12.381a.491.491,0,0,1-.555.324Z"
            stroke="none"
          />
          <path
            d="M 1831.260620117188 280.9559631347656 C 1831.353393554688 280.9559631347656 1831.43603515625 280.8966979980469 1831.466186523438 280.8085021972656 C 1831.467529296875 280.8043823242188 1831.469116210938 280.80029296875 1831.470703125 280.7962341308594 L 1836.3759765625 268.4195556640625 C 1836.3984375 268.3599243164062 1836.392211914062 268.29150390625 1836.3583984375 268.2350158691406 C 1836.3271484375 268.1819763183594 1836.275756835938 268.1452331542969 1836.216064453125 268.1327209472656 L 1833.974365234375 268.0587158203125 L 1828.963989257812 280.6954956054688 L 1831.200927734375 280.94921875 C 1831.20751953125 280.9499816894531 1831.214111328125 280.9509887695312 1831.220581054688 280.9522094726562 C 1831.23388671875 280.9547119140625 1831.247314453125 280.9559631347656 1831.260620117188 280.9559631347656 M 1831.260620117188 281.2294921875 C 1831.230590820312 281.2294921875 1831.200317382812 281.2267150878906 1831.170043945312 281.2210083007812 L 1828.578002929688 280.927001953125 L 1833.791015625 267.7789916992188 L 1836.244018554688 267.8599853515625 C 1836.390991210938 267.8819885253906 1836.51904296875 267.968994140625 1836.593994140625 268.0960083007812 C 1836.670043945312 268.2229919433594 1836.683959960938 268.3779907226562 1836.631958007812 268.5159912109375 L 1831.724975585938 280.8970031738281 C 1831.656005859375 281.0986938476562 1831.466674804688 281.2294921875 1831.260620117188 281.2294921875 Z"
            stroke="none"
            fill="#6b6965"
          />
        </g>
      </g>
    </g>
  </svg>
)
