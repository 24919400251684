import React, { FC, ReactElement } from 'react'
import { KlosySVGId } from '../types/svg'

export const Yanagiba: FC = (): ReactElement => (
  <svg id={KlosySVGId.yanagiba} viewBox="0 0 195.847 20.586" x="0" y="0">
    <g
      id="Nóż_do_porcjowania_Carving_Yanagiba"
      data-name="Nóż do porcjowania/Carving/Yanagiba"
      transform="translate(0.273 0.273)"
    >
      <path
        id="Path_395"
        data-name="Path 395"
        d="M1828.578,688.907l2.569.227a.49.49,0,0,0,.551-.33l4.756-12.393a.491.491,0,0,0-.4-.651l-2.418-.151-14.885-.151-14.886.151-14.885.453-14.81.831a17.848,17.848,0,0,0,3.324,10.73l12.77-.151,12.77.151,12.77.453Z"
        transform="translate(-1641.186 -675.458)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <path
        id="Path_396"
        data-name="Path 396"
        d="M1777.5,687.623l-3.222.082a7.669,7.669,0,0,0-7.355,7.792l-40.956-.545-40.878-.755-10.277-.529-10.276-.756-10.276-.982-4.458-.68-4.383-.982-4.231-1.361,7.782-2.418,6.348-1.284,6.347-.982,6.422-.756,6.423-.529,45.11-3.022,54.556-3.023A17.848,17.848,0,0,0,1777.5,687.623Z"
        transform="translate(-1641.186 -675.458)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <path
        id="Path_397"
        data-name="Path 397"
        d="M1770.773,688.681l-.906-8.085a3.263,3.263,0,0,0-2.216-1.74"
        transform="translate(-1641.186 -675.458)"
        fill="none"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.274"
      />
      <g
        id="Group_224"
        data-name="Group 224"
        transform="translate(-1641.186 -675.458)"
      >
        <path
          id="Path_398"
          data-name="Path 398"
          d="M1804.851,677.913a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_399"
          data-name="Path 399"
          d="M1804.927,685.167a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_400"
          data-name="Path 400"
          d="M1826.538,677.838a.491.491,0,1,0-.492.491.491.491,0,0,0,.492-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_401"
          data-name="Path 401"
          d="M1823.364,686a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_402"
          data-name="Path 402"
          d="M1780.747,678.9a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_403"
          data-name="Path 403"
          d="M1782.788,685.016a.491.491,0,1,0-.492.491.491.491,0,0,0,.492-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <g
          id="Path_404"
          data-name="Path 404"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M1828.578,688.907l5.062-13.3,2.418.151a.491.491,0,0,1,.4.651L1831.7,688.8a.49.49,0,0,1-.551.33Z"
            stroke="none"
          />
          <path
            d="M 1831.232177734375 688.8678588867188 C 1831.326049804688 688.8678588867188 1831.408813476562 688.8079833984375 1831.438110351562 688.7188110351562 C 1831.439453125 688.7144775390625 1831.441040039062 688.7102661132812 1831.442626953125 688.7059936523438 L 1836.197021484375 676.3171997070312 C 1836.217895507812 676.2554321289062 1836.2109375 676.1890869140625 1836.177124023438 676.1338500976562 C 1836.144287109375 676.0796508789062 1836.090576171875 676.04296875 1836.028930664062 676.0322265625 L 1833.823974609375 675.89453125 L 1828.962280273438 688.6663818359375 L 1831.171020507812 688.8615112304688 C 1831.178833007812 688.8621826171875 1831.1865234375 688.8632202148438 1831.194091796875 688.8645629882812 C 1831.206787109375 688.8667602539062 1831.219604492188 688.8678588867188 1831.232177734375 688.8678588867188 M 1831.232177734375 689.1414184570312 C 1831.203979492188 689.1414184570312 1831.175537109375 689.1389770507812 1831.14697265625 689.1339721679688 L 1828.578002929688 688.906982421875 L 1833.640014648438 675.6090087890625 L 1836.057983398438 675.760009765625 C 1836.204956054688 675.780029296875 1836.333984375 675.864990234375 1836.411010742188 675.9920043945312 C 1836.488037109375 676.1179809570312 1836.503051757812 676.27197265625 1836.453979492188 676.4110107421875 L 1831.697998046875 688.8040161132812 C 1831.630981445312 689.0084228515625 1831.440673828125 689.1414184570312 1831.232177734375 689.1414184570312 Z"
            stroke="none"
            fill="#6b6965"
          />
        </g>
      </g>
    </g>
  </svg>
)
