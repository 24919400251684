import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const Chef210: FC = (): ReactElement => (
  <svg id={KlosySVGId.chef210} viewBox="0 0 166.558 29.865" x="0" y="0">
    <g
      id="Nóż_szefa_kuchni_210_mm_8_cali_"
      data-name="Nóż szefa kuchni 210 mm (8 cali)"
      transform="translate(0.273 0.273)"
    >
      <path
        id="Path_296"
        data-name="Path 296"
        d="M1771.984,92.512l-.153,8.234-9.6-.151-22.9-.907-22.9-1.209-10.428-.907-10.352-1.36-10.276-1.662-4.987-1.134-5.063-1.284-4.911-1.512.028-.062a62.539,62.539,0,0,1,11.339-5.253l-.036.025a60.706,60.706,0,0,1,8.468-2.58l0,.012,5.969-1.133,6.045-.983,6.121-.755,59.844-5.063,5.971-.507a17.72,17.72,0,0,0,3.658,10.574,8.186,8.186,0,0,0-5.849,7.617"
        transform="translate(-1670.428 -71.428)"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
      />
      <path
        id="Path_297"
        data-name="Path 297"
        d="M1777.833,84.895l12.737-.471,12.77-.151,12.77.076,12.77.453,2.611.146a.49.49,0,0,0,.542-.345l4.379-12.532a.491.491,0,0,0-.413-.64l-.016,0h-2.418l-14.886.151-14.885.6-14.81.906-14.809,1.231A17.72,17.72,0,0,0,1777.833,84.895Z"
        transform="translate(-1670.428 -71.428)"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
      />
      <g
        id="Group_159"
        data-name="Group 159"
        transform="translate(-1670.428 -71.428)"
      >
        <path
          id="Path_298"
          data-name="Path 298"
          d="M1804.851,74.564a.491.491,0,1,0-.491.491.49.49,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_299"
          data-name="Path 299"
          d="M1805.154,81.742a.492.492,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_300"
          data-name="Path 300"
          d="M1826.538,73.808a.491.491,0,1,0-.492.491.491.491,0,0,0,.492-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_301"
          data-name="Path 301"
          d="M1823.591,82.044a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_302"
          data-name="Path 302"
          d="M1780.823,76.15a.491.491,0,1,0-.491.492.491.491,0,0,0,.491-.492"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_303"
          data-name="Path 303"
          d="M1783.014,82.271a.491.491,0,1,0-.491.491.491.491,0,0,0,.491-.491"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <g
          id="Path_304"
          data-name="Path 304"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M1828.88,84.8l2.611.146a.49.49,0,0,0,.542-.345l4.379-12.532a.491.491,0,0,0-.413-.64l-.016,0h-2.418Z"
            stroke="none"
          />
          <path
            d="M 1831.563598632812 84.67989349365234 C 1831.658325195312 84.67989349365234 1831.743530273438 84.61605834960938 1831.770874023438 84.52467346191406 C 1831.772094726562 84.52066802978516 1831.773315429688 84.51670837402344 1831.774780273438 84.51277160644531 L 1836.15283203125 71.98355865478516 C 1836.171997070312 71.92302703857422 1836.162841796875 71.85502624511719 1836.128295898438 71.8013916015625 C 1836.092407226562 71.74588775634766 1836.034545898438 71.70999908447266 1835.969482421875 71.70291137695312 C 1835.9658203125 71.70252227783203 1835.962280273438 71.70205688476562 1835.958618164062 71.70152282714844 L 1833.758911132812 71.70152282714844 L 1829.258422851562 84.54921722412109 L 1831.506225585938 84.67491149902344 C 1831.5146484375 84.67538452148438 1831.523071289062 84.67623901367188 1831.531372070312 84.677490234375 C 1831.542114257812 84.67908477783203 1831.552856445312 84.67989349365234 1831.563598632812 84.67989349365234 M 1831.563598632812 84.95340728759766 C 1831.53955078125 84.95340728759766 1831.515258789062 84.95163726806641 1831.490966796875 84.947998046875 L 1828.880004882812 84.802001953125 L 1833.56494140625 71.42800140380859 L 1835.983032226562 71.42800140380859 L 1835.9990234375 71.43099975585938 C 1836.14599609375 71.44699859619141 1836.277954101562 71.52899932861328 1836.358032226562 71.65299987792969 C 1836.43798828125 71.77700042724609 1836.4580078125 71.93099975585938 1836.411987304688 72.07099914550781 L 1832.032958984375 84.60300445556641 C 1831.970092773438 84.81336212158203 1831.777099609375 84.95341491699219 1831.563598632812 84.95340728759766 Z"
            stroke="none"
            fill="#6b6965"
          />
        </g>
      </g>
    </g>
  </svg>
)
