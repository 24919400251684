import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const Peeling: FC = (): ReactElement => (
  <svg id={KlosySVGId.peeling} viewBox="0 0 106.848 13.676" x="0" y="0">
    <g
      id="Nożyk_do_obierania"
      data-name="Nożyk do obierania"
      transform="translate(0.274 0.274)"
    >
      <path
        id="Path_345"
        data-name="Path 345"
        d="M1784.353,432.657l-2.623-.035a6.854,6.854,0,0,0-6.075,3.986l-13.345-.737-13.374-1.058-13.223-1.36a88.633,88.633,0,0,1-10.237-2.073,20.548,20.548,0,0,1,3.81-1.8,72.827,72.827,0,0,1,10.511-2.249l0,0,11.41-1.284,11.485-.983,11.486-.831,7.949-.307A15.978,15.978,0,0,0,1784.353,432.657Z"
        transform="translate(-1725.476 -423.479)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <path
        id="Path_346"
        data-name="Path 346"
        d="M1784.353,432.657l8.635.116,11.183.453,11.259.831,11.183,1.134a1.476,1.476,0,0,0,1.138-.413,18.259,18.259,0,0,0,4.026-9.805.868.868,0,0,0-.555-.814l-2.342-.151-13.677-.454-13.676-.075-13.677.226-5.727.222A15.978,15.978,0,0,0,1784.353,432.657Z"
        transform="translate(-1725.476 -423.479)"
        fill="var(--color-hover, transparent)"
        style={{ transition: '.3s' }}
        className="color-transition"
        stroke="#6b6965"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.547"
      />
      <g
        id="Group_195"
        data-name="Group 195"
        transform="translate(-1725.476 -423.479)"
      >
        <path
          id="Path_347"
          data-name="Path 347"
          d="M1788.152,425.783a.415.415,0,1,0-.415.416.415.415,0,0,0,.415-.416"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_348"
          data-name="Path 348"
          d="M1789.361,430.468a.415.415,0,1,0-.415.416.415.415,0,0,0,.415-.416"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_349"
          data-name="Path 349"
          d="M1806.136,431.148a.415.415,0,1,0-.416.416.415.415,0,0,0,.416-.416"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_350"
          data-name="Path 350"
          d="M1806.136,425.481a.415.415,0,1,0-.416.416.416.416,0,0,0,.416-.416"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_351"
          data-name="Path 351"
          d="M1826.462,426.161a.416.416,0,1,0-.416.416.415.415,0,0,0,.416-.416"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
        <path
          id="Path_352"
          data-name="Path 352"
          d="M1824.271,432.13a.416.416,0,1,0-.416.416.415.415,0,0,0,.416-.416"
          fill="none"
          stroke="#6b6965"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.274"
        />
      </g>
    </g>
  </svg>
)
